<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="details.trustees_backup_required"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/trustees/secondary'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'TrusteesBackupOption',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    people() {
      return this.$store.getters.people
    },
    partner() {
      return this.$store.getters.partner
    },
    isValidated() {
      return true
    },
    number() {
      return '4'
    },
    heading() {
      return 'Would you like to name backup trustees?'
    },
    subHeading() {
      return (
        'You have named your ' +
        this.partner.relationship +
        ' as your main trustee and you have named ' +
        this.secondaryTrusteeText +
        'If you wish you can go further and appoint a final person (or persons).'
      )
    },
    secondaryTrusteeText() {
      var peopleList = this.people.filter((person) => person.trustee_secondary)
      if (peopleList.length > 1)
        return (
          this.listPeople(peopleList, ' and ', true) +
          ' as secondary trustees.  '
        )
      return this.listPeople(peopleList) + ' as a secondary trustee.  '
    },
    forwardTo() {
      if (this.details.trustees_backup_required) return '/trustees/backup'
      return '/trustees/check_and_confirm'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          trustees_backup_required: !this.details.trustees_backup_required
        })
        if (this.details.trustees_backup_required === false) {
          this.resetTrusteeBackup()
        }
      }
    },
    resetTrusteeBackup() {
      this.people.forEach((person) => {
        let personCopy = Object.assign({}, person)
        personCopy.trustee_backup = false
        this.$store.commit('personAdd', personCopy)
      })
    }
  }
}
</script>

<style></style>
